.LoginContainer {
    display: flex;
}

/* .leftPanel {
    flex: 1.5;
} */

/* .rightPanel {
    flex: 2;
} */

.divider {
    width: 0.5px;
    background-color: #f6f6f6;
    height: calc(100vh - 15vh);
    margin: 0 50px;
}

.LoginDetailContainer {
    /* padding: 1rem 5rem; */
}

.LoginDetailContainer input:not[type="checkbox"] {
    background: #fff;
    border: 1px solid #d9d9d9;
    height: 40px !important;
    padding: 10px 15px;
    width: 100% !important;
}

.LoginDetailContainer .userId {
    background: #fff;
    border: 1px solid #d9d9d9;
    height: 40px;
    padding: 10px 15px;
    width: 100%;
}

.RegisterContainer {
    /* padding: 1rem 5rem; */
}

.RegisterContainer input,
.RegisterContainer select {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    height: 40px;
    padding: 10px 15px;
}

.registerForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 30px;
}

.inputRegister {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
}

/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

/* input[type="number"] {
    -moz-appearance: textfield;
} */

.methodButtons {
    display: flex;
    background-color: var(--secondary-color);
    width: 19rem;
    padding: 5px;
    margin-bottom: 10px;
}

.methodButtons button {
    padding: 5px 20px;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 !important;
}

.methodButtons button:focus {
    outline: none;
}

.passwordButton {
    color: white;
    background-color: var(--secondary-color);
}

.otpButton {
    color: white;
    background-color: var(--secondary-color);
}

.btn-lg {
    background-color: var(--primary-background-color);
    color: white;
    padding: 10px;
    width: 100%;
}

.passwordContainer {
    position: relative;
}

.activeButton {
    background: var(--primary);
}

.labelContainer {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    align-items: center;
}

.input {
    position: relative;
}

.input label {
    display: block;
    color: #828282;
    display: flex;
    font-weight: unset;
    align-items: center;
}

.inputRegister label {
    display: block;
    color: #828282;
    display: flex;
    font-weight: unset;
    font-size: 15px;
    margin-bottom: 0.1rem;
}

/* .userId, */
/* .email,
.mobile,
.adharCardNo,
.dob,
.fatherName,
.name,
.language {
    /* width: 100%; */
/* padding: 5px 10px; */
/* font-weight: unset; */
/* line-height: 1.5; */
/* background-color: #fff; */
/* color: #484848; */
/* font-size: 1rem; */
/* margin-top: 1%; */
/* border-radius: 5px; */
/* background: transparent; */
/* border: none; */
/* border: 1px solid lightgrey; */
/* } */

.password,
.confirmPassword {
    /* width: 100%; */
    position: relative;
    padding: 5px 10px;
    font-weight: unset;
    line-height: 1.5;
    background-color: #fff;
    color: #484848;
    font-size: 1rem;
    /* margin-top: 1%; */
    /* border-radius: 5px; */
    /* background: transparent; */
    /* border: none; */
    border: 1px solid lightgrey;
}

.eyeButton {
    position: absolute;
    top: 3.3rem;
    right: 30px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
}

.inputRow {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.userId:focus,
.email:focus,
.mobile:focus,
.adharCardNo:focus,
.dob:focus,
.fatherName:focus,
.name:focus,
.language:focus {
    outline: none;
    /* This removes the default focus outline */
    /* Add your focus styling here */
}

.password:focus {
    outline: none;
}

.eyeButton:focus {
    outline: none;
}

.forgetPassword {
    color: #828282;
    font-size: 15px;
}

.error {
    color: var(--primary);
    /* margin-top: 3%; */
    font-size: 12px;
    position: absolute;
    bottom: -18px;
}

.btn {
    border: none;
    text-decoration: none;
    background-color: var(--primary-background-color);
    padding: 8px 8px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 1%;
}

.otpBtn {
    border: none;
    text-decoration: none;
    background-color: var(--primary-background-color);
    padding: 8px 8px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 3rem;
}

.btn:hover {
    opacity: 0.8;
}

.loginPassword {
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 40px;
    padding: 10px 15px;
    background: #fff;
}

.loginPasswordEyeButton {
    position: absolute;
    top: 3.5rem;
    right: 15px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
}

.RegisterContainer .form-control::file-selector-button {
    padding: 0rem 0.75rem;
    margin: 0rem -0.75rem;
}

.RegisterContainer .form-control::-webkit-file-upload-button {
    padding: 0rem 0.75rem;
    margin: 0rem -0.75rem;
    -webkit-margin-end: 0.8rem;
    margin-inline-end: 0.8rem;
}

.LoginDetailContainer button,
.RegisterContainer button {
    height: 48px;
}

@media all and (max-width: 991px) {

    .LoginDetailContainer,
    .RegisterContainer {
        margin-bottom: 50px;
    }
}