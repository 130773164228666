.home-button-parent{
	display:flex;
	flex-wrap:wrap;
}
.homebtn{
	background:#fff;
	border:1px solid #ccc;
	width:170px;
    padding: 15px;
	color:var(--black);
}
button + button{
    margin-left: 30px;
}
.homebtn_active{
	width:170px;
    padding: 15px;
	background:var(--primary) !important;
	color:#fff;
	border:none;
    position: relative;
}
@media (max-width:767px){
    .homebtn,.homebtn_active{
        width:100%;
        margin:5px 0px !important;
    }

}
/* .homebtn_active svg{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
} */