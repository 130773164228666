.card_bg_color {
    background-color: #f9f9f9;
}

.card_count_text_color {
    color: var(--primary);
}

.red_line {
    border-top: 1px solid var(--primary);
}

.tab {
    cursor: pointer;
    padding: 10px;
    margin: 0px 5px 0px 0px;
    border: 1px solid #EFEFEF !important;
}

.tab_text {
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
}

.active_tab {
    background-color: var(--primary);
    position: relative;
    border: none !important;
    border-bottom: 1px solid var(--primary) !important;
}

.active_tab_text {
    color: white !important;
}

.active_tab:before {
    content: '';
    display: block;
    background-color: #767676 !important;
    height: 5px;
    width: 100%;
    top: -3px;
    position: absolute;
    left: 0;
}

.filter_btn {
    cursor: pointer;
    padding: 5px 30px;
    background-color: var(--primary);
}

.filter_btn_text {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.filter_element {
    margin-right: 5px;
}

.clear_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 5px 20px;
    cursor: pointer;
    margin-left: 5px;
}

.clear_btn:hover {
    background-color: var(--primary);
    color: white;
}

.doc_icon {
    height: 40px;
    width: 40px;
    margin-left: 5px;
}

.pagination_dropdown {
    height: 30px;
    padding: 0 0 0 5px;
    margin-left: 5px;
    border-color: #D9D9D9;
}

.pagination_count {
    border: 1px solid #D9D9D9;
    padding: 5px 10px;
    margin-left: 5px;
}

.page_active_count {
    background-color: var(--primary);
    color: white;
}

thead {
    background-color: #F8F8F8;
}

th,
td {
    padding: 5px 10px;
}

.filter_dropdown {
    border-color: #D9D9D9;
}