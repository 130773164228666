.NavbarContainerLogin {
  position: relative;
  width: 100%;
  height: 15vh;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavbarContainer {
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  /* Center vertically */
}

.btnWrapper {
  display: flex;
  gap: 1rem;
}

.navbarWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding: 30px 60px;
}

.profilebtn {
  border: 1px solid #727372;
  color: #727372;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.profilebtn:hover {
  background-color: rgba(116, 113, 113, 0.2);

  /* opacity: 0.1; */
}

.profilebtn span {
  display: flex;
  align-items: center;
}

.logoutbtn:hover {
  background-color: rgba(202, 44, 44, 0.2);
}

.logoutbtn {
  border: 1px solid #b13a25;
  color: var(--primary-color);
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
  gap: 2px;


}

.logoutbtn span {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .navbarWrapper {
    padding: 5px 30px;
  }

  .logo {
    width: 50%;
  }
}